btnTextColor       = #fff
btnBgColor         = #56b64b
btnDisabledBgColor = #ccc
btnHeight          = 35px
btnFontSize        = rem(14)
btnLineHeight      = btnHeight

btnBorderHeight    = 40px
btnBorderTextColor = #5a3600
btnBorderColor     = #ffae23

.g-btn-border
	display inline-block
	position relative
	height btnBorderHeight
	line-height btnBorderHeight - 4
	color btnColor
	border-bottom 2px solid btnBorderColor
	border-top 2px solid btnBorderColor

.g-btn-border--left
	absolute($top:-2px,$left:-12px)
	size(40px)
	overflow hidden

	&:after
		absolute($top:-1px,$left:0)
		arrow-left(btnBorderColor,21px,12px)

	&:before
		absolute($top:2px,$left:2px)
		z-index 1
		arrow-left(#fff,18px,10px)


.g-btn-border--right
	absolute($top:-2px,$right:-12px)
	size(40px)
	overflow hidden

	&:after
		absolute($top:-1px,$right:0)
		arrow-right(btnBorderColor,21px,12px)

	&:before
		absolute($top:2px,$right:2px)
		z-index 1
		arrow-right(#fff,18px,10px)

.g-btn-border__link
	display block
	size(100%)
	padding 0 40px


.g-btn
	height btnHeight
	line-height btnLineHeight
	padding 0 20px
	background-color btnBgColor
	border-radius 40px
	color btnTextColor
	font-size btnFontSize
	text-align center
	border 0
	cursor pointer

	&.g-btn--disabled
		background-color btnDisabledBgColor

	&.g-btn--big
		display block
		width 250px
		margin 0 auto
		height 50px
		line-height 50px
		font-size rem(16)