.map__img
	size(100%)
	min-width 1400px

.map-compass
	absolute($top:105px,$right:60px)
	z-index 1
	fill chooseCompassColor
	max-width 300px

	@media screen and (max-width bigPhoneWidth)
		top 195px

.map-info
	absolute($bottom:70px,$right:60px)
	z-index 1
	width 100%
	max-width 230px
	color chooseInfoTextColor