chooseHouseBgColor      = #ffae23
chooseHouseBgColorHover = #ffd276
chooseHouseIconColor    = #88480e
chooseHouseArrowColor   = #663300
chooseHouseTextColor    = #3f2b0d
chooseHouseFontSize     = rem(18)
chooseHouseFontStyle    = italic
chooseHouseFontWeight   = 500

.choose-house
	position relative
	display flex
	align-items center
	padding 10px 30px
	background-color chooseHouseBgColor
	cursor pointer
	transition all linear .3s

	@media screen and (max-width smallMobileWidth)
		width 106px
		padding 7px 20px

	&:hover
		background-color chooseHouseBgColorHover

	&:before
		absolute($top:0,$bottom:0,$left:0)
		arrow-right(chooseHouseArrowColor,25px,12px)

		@media screen and (max-width smallDesktop)
			display none

	.icon
		margin-right 10px
		size(35px,30px)
		fill chooseHouseIconColor

		@media screen and (max-width smallDesktop)
			display none

.choose-house__text
	font-size chooseHouseFontSize
	font-style chooseHouseFontStyle
	font-weight chooseHouseFontWeight
	color chooseHouseTextColor

	@media screen and (max-width smallMobileWidth)
		font-size rem(14)