sliderDotBgColor       = #c4c4c4
sliderActiveDotBgColor = #3fb29f
gallerySliderIconColor      = #fff
gallerySliderIconHoverColor = #ffae23

.gallery-slider
	position relative

.slider-controls
	absolute($top:50%)
	z-index 2
	transform translateY(-50%)
	cursor pointer

	.icon
		size(30px)
		fill gallerySliderIconColor
		transition all linear .3s

	&:hover

		.icon
			fill gallerySliderIconHoverColor

.slider-controls--prev
	left 15px

	&.advantage-slider-prev
		left 5px

.slider-controls--next
	right 15px

	&.advantage-slider-next
		right 5px

.gallery-slider

	.slick-dots
		margin-top 25px
		text-align center

		li
			display inline-block
			vertical-align middle
			margin 0 5px
			cursor pointer

.slick-active

	.slider-dot
		size(8px)
		transform rotate(45deg)
		background-color sliderActiveDotBgColor
		border-radius 0


.slider-dot
	display block
	size(5px)
	border-radius 50%
	background-color sliderDotBgColor
	cursor pointer