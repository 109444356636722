advantagesBgColor        = rgba(102,51,0,1)
advantagesBgColorOpacity = rgba(102,51,0,.7)
advantageBgColor         = #fff
advantageIconBgColor     = #ecd6ac
advantageIconColor       = #5a3600
advantageIconBorderColor = #dabc8f

advantageTitleColor      = #3f2b0d
advantageTitleSize       = rem(24)
advantageTitleWeight     = 600

advantageTextColor       = #333
advantageTextSize        = rem(14)

advantageLinkColor       = #533303
advantageLinkHoverColor  = #ffae23
advantageLinkSize        = rem(13)
advantageLinkWeight      = 500
advantageLinkBorderColor = #c4c4c4

.s-advantages
	background-color advantagesBgColor
	transition all linear .6s

	@media screen and (max-width phabletWidth)
		padding-top 70px
		padding-bottom 30px

	&.adv-half-opacity
		background-color advantagesBgColorOpacity

		@media screen and (max-width phabletWidth)
			background-color advantagesBgColor

	.container
		position relative
		top -25px
		margin-bottom -85px

		@media screen and (max-width phabletWidth)
			top 0
			margin-bottom 0

	.g-section-title
		margin-bottom 78px

		@media screen and (max-width phabletWidth)
			margin-bottom 40px

.advantages__border-line
	padding-left 80px
	margin-bottom 60px

	@media screen and (max-width phabletWidth)
		display none

.promo-line__text
	color promoLineTextColor
	font-size promoLineTextSize

.promo-line__btn
	absolute($top:5px,$right:5px)
	z-index 1

.advantages-block
	display flex
	justify-content space-between

	@media screen and (max-width phabletWidth)
		display block

	.slick-dots
		margin-top 30px
		text-align center

		li
			display inline-block
			margin 0 10px
			vertical-align middle

.advantage-wrap
	padding 62px 0 40px
	flex 0 0 240px
	width 240px

.advantage
	position relative
	background-color advantageBgColor
	text-align center
	padding 0 20px 30px
	width 240px
	max-width 240px
	margin 0 auto

	&:after
		absolute($bottom:-40px,$left:0)
		arrow-bottom(advantageBgColor,120px,40px)

.advantage-icon__bottom-arrow
	absolute($bottom:-24px,$left:0)
	size(140px,28px)
	overflow hidden

	&:after
		absolute($top:2px,$left:-6px)
		arrow-bottom(advantageIconBorderColor,77px,26px)

	&:before
		absolute($top:0,$left:-6px)
		z-index 1
		arrow-bottom(advantageIconBgColor,77px,26px)

.advantage-icon
	relative($top:-38px,$left:50%)
	size(140px,84px)
	margin-left -70px
	background-color advantageIconBgColor

	&:before
		absolute($top:-24px,$left:0)
		arrow-top(advantageIconBgColor,70px,24px)

	.icon
		absolute-center()
		fill advantageIconColor

	.icon-city
		size(75px,54px)

	.icon-sun
		size(61px,56px)

	.icon-check
		size(51px)

.advantage__title
	display block
	margin-bottom 15px
	color advantageTitleColor
	font-size advantageTitleSize
	font-weight advantageTitleWeight

.advantage__text
	color advantageTextColor
	font-size advantageTextSize

.advantage-link
	absolute($right:35px,$bottom:0,$left:35px)
	letter-spacing .65px

.advantage-link__text
	color advantageLinkColor
	font-size advantageLinkSize
	font-weight advantageLinkWeight
	transition all linear .3s

	&:hover
		color advantageLinkHoverColor

	&:before,
	&:after
		content ''
		display inline-block
		size(40px,1px)
		background-color advantageLinkBorderColor
		vertical-align middle

	&:before
		margin-right 10px

	&:after
		margin-left 10px

@import "advantages/popup.styl"