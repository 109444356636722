mapPopupBgColor           = #fff
mapPopupBorderColor       = #ccc
mapPopupCloseIconColor    = #707070

.map-popup
	display none
	absolute($top:45px,$right:50px)
	z-index 5
	padding 20px
	min-width 370px
	background-color mapPopupBgColor

	@media screen and (max-width smallMobileWidth)
		top 50px
		right 0
		left 0
		bottom 0
		min-width auto

	&.map-popup--show
		display block


.map-popup--housing

	.map-p-screen

		&.map-p-screen--housing
			display block

.map-popup--house

	.map-p-screen

		&.map-p-screen--house
			display block

.map-popup--house-book

	.map-p-screen

		&.map-p-screen--house-book
			display block

.map-popup--house-info
	max-width 930px

	@media screen and (max-width bigPhablet)
		max-width 700px

	.map-p-screen

		&.map-p-screen--house-info
			display block

.map-p-screen
	display none

.map-popup-top
	display flex
	margin-bottom 20px

.map-popup__close
	position relative
	size(32px)
	margin-left auto
	border 1px solid mapPopupBorderColor
	border-radius 50%
	cursor pointer

	@media screen and (max-width smallMobileWidth)
		display none

	.icon
		absolute-center()
		size(10px)
		fill mapPopupCloseIconColor

.map-popup-close-mobile
	display none

	@media screen and (max-width smallMobileWidth)
		display block

	.icon
		size(27px,14px)
		flex-shrink 0
		margin-right 10px
		fill mapPopupBackIconColor

.map-popup-back
	display flex
	align-items center
	font-size rem(14)
	cursor pointer

	.icon
		size(27px,14px)
		flex-shrink 0
		margin-right 10px
		fill mapPopupBackIconColor


@import "popup/housing-screen.styl"
@import "popup/house-screen.styl"
@import "popup/house-info.styl"
@import "popup/house-book.styl"