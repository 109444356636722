advantagePopupWidth          = 1080px
advantageImgWidth            = 400px
advantageContentBgColor      = #fff
advantagePopupFontSize       = rem(26)
advantagePopupFontWeight     = 600
advantagePopupBorderColor    = #ccc
advantagePopupCloseIconColor = #707070

.advantage-popup
	position relative
	width 100%
	max-width advantagePopupWidth
	margin 0 auto
	padding 0 75px

	@media screen and (max-width phabletWidth)
		padding 0 5px


.advantage-popup__close
	absolute($top:20px,$right:20px)
	size(32px)
	border-radius 50%
	border 1px solid advantagePopupBorderColor
	z-index 1
	cursor pointer
	transition all linear .4s

	&:hover
		background-color advantagePopupCloseIconColor

		.icon
			fill advantagePopupBorderColor

	.icon
		absolute-center()
		size(10px)
		fill advantagePopupCloseIconColor
		transition all linear .4s

.advantage-info
	display flex

	@media screen and (max-width phabletWidth)
		flex-direction column

.advantage-info__photo
	width 45%
	flex-shrink 0
	max-width advantageImgWidth
	background-size cover

	@media screen and (max-width phabletWidth)
		display none

.advantage-info__content
	position relative
	padding 70px
	background-color advantageContentBgColor

	@media screen and (max-width phabletWidth)
		padding 50px 30px

.advantage-info__title
	display block
	margin-bottom 15px
	font-size advantagePopupFontSize
	font-weight footerProjectWeight

.advantage-info__text
	line-height 1.7



.slider-controls

	&.advantage-slider-prev,
	&.advantage-slider-next

		.icon

			@media screen and (max-width phabletWidth)
				fill #000
