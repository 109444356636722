mainTitleSize            = rem(50)
mainTitlePhabletSize     = rem(40)
mainTitleMobileSize      = rem(30)
mainTitleNameColor  = #ffae23
mainTitleFontWeight = 400

sectionTitleSize       = rem(50)
sectionTitlePhabletSize = rem(40)
sectionTitleMobileSize = rem(32)
sectionTitleFontWeight = 600
sectionTitleColor      = #fff
sectionTitleColorText  = #3f2b0d

blockTitleSize       = rem(28)
blockTitleFontWeight = 600
blockTitleColor      = #3f2b0d

.main-title
	margin-bottom 40px
	font-weight mainTitleFontWeight
	font-size mainTitleSize
	text-shadow 0 2px 4px rgba(0, 0, 0, 0.15)

	@media screen and (max-width phabletWidth)
		font-size mainTitlePhabletSize

	@media screen and (max-width bigPhoneWidth)
		font-size mainTitleMobileSize

.main-title__name
	color mainTitleNameColor

.g-section-title
	margin-bottom 40px
	color sectionTitleColor
	text-align center

	h2
		display inline-block
		font-size sectionTitleSize
		font-weight sectionTitleFontWeight

		@media screen and (max-width phabletWidth)
			font-size sectionTitlePhabletSize

		@media screen and (max-width bigPhoneWidth)
			font-size sectionTitleMobileSize

		&:before,
		&:after
			content ''
			display inline-block
			size(55px,2px)
			background-color sectionTitleColor
			vertical-align middle

			@media screen and (max-width bigPhoneWidth)
				display none

		&:before
			margin-right 15px

		&:after
			margin-left 15px

	&.g-section-title--text

		h2
			color sectionTitleColorText

			&:before,
			&:after
				display none

	&.g-section-title--no-line

		h2

			&:before,
			&:after
				display none


.g-block-title
	margin-bottom 15px
	font-size blockTitleSize
	font-weight blockTitleFontWeight
	color blockTitleColor