absolute-center()
	position absolute
	top 50%
	left 50%
	transform translate(-50%,-50%)

absolute-all(num)
	position absolute
	top num
	bottom num
	right num
	left num

position($position, $top = null, $right = null,  $bottom = null, $left = null)
	position: $position
	if $left is a 'unit' or $left == 'auto'
		left: $left
	if $right is a 'unit' or $right == 'auto'
		right: $right
	if $top is a 'unit' or $top == 'auto'
		top: $top
	if $bottom is a 'unit' or $bottom == 'auto'
		bottom: $bottom

absolute($top = null, $right = null,  $bottom = null, $left = null)
	position(absolute, $top, $right, $bottom, $left)

fixed($top = null, $right = null,  $bottom = null, $left = null)
	position(fixed, $top, $right, $bottom, $left)

relative($top = null, $right = null,  $bottom = null, $left = null)
	position(relative, $top, $right, $bottom, $left)

rem(size)
	return (size/16)rem

size(a, b = false)
	width a
	if b
		height b
	else
		height a


arrow-left(col,h,w)
	content ''
	size(0)
	border-style solid
	border-width h w h 0
	border-color transparent col transparent transparent

arrow-right(col,h,w)
	content ''
	size(0)
	border-style solid
	border-width h 0 h w
	border-color transparent transparent transparent col


arrow-top(col,h,w)
	content ''
	size(0)
	border-style solid
	border-width 0 h w h
	border-color transparent  transparent col transparent

arrow-bottom(col,h,w)
	content ''
	size(0)
	border-style solid
	border-width w h 0 h
	border-color col transparent transparent transparent
