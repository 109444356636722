aboutTextFontSize   = rem(24)
aboutTextFontSizePhablet   = rem(20)
aboutTextFontSizeMobile   = rem(16)
aboutTextLineHeight = 1.5
aboutTextColor      = #fff

.s-about
	padding 180px 0 100px
	min-height 1000px
	background-size cover

	@media screen and (max-width phabletWidth)
		padding 40px 0


.about

	.tabs-content

		@media screen and (max-width phabletWidth)
			padding 0 20px

.about__border-line
	margin-bottom 100px

	@media screen and (max-width phabletWidth)
		margin-bottom 50px

	.border-line-wrap-inner
		min-width 700px

.about__tabs-list
	flex 1 0 auto
	justify-content space-between

.about__text
	font-size aboutTextFontSize
	line-height aboutTextLineHeight
	color aboutTextColor
	text-align justify

	@media screen and (max-width phabletWidth)
		font-size aboutTextFontSizePhablet

	@media screen and (max-width bigPhoneWidth)
		font-size aboutTextFontSizeMobile