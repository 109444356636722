promoLineBgColor   = #fff
promoLineHeight    = 50px
promoLineTextColor = #5a3600
promoLineTextSize  = rem(18)

.border-line-wrap
	position relative
	z-index 1

	@media screen and (max-width phabletWidth)
		overflow auto

.border-line-wrap-inner
	padding 0 35px


.border-line
	position relative
	display flex
	align-items center
	height promoLineHeight
	line-height promoLineHeight
	background-color promoLineBgColor

	&:after
		absolute($top:0,$bottom:0,$right:-12px)
		arrow-right(promoLineBgColor,25px,12px)

	&:before
		absolute($top:0,$bottom:0,$left:-12px)
		arrow-left(promoLineBgColor,25px,12px)