tabLinkFontSize      = rem(18)
tabLinkSmallFontSize = rem(14)
tabLinkHeight        = 40px
tabLinkLineHeight    = 36px
tabActiveLinkColor   = #eca328

.tabs-list
	display flex
	justify-content center
	font-size tabLinkFontSize

	&.tabs-list--small
		font-size tabLinkSmallFontSize

.tabs-list__text
	display block
	line-height tabLinkHeight
	transition all linear .2s

.tabs-list__item--border,
.tabs-list__item--border-transparent

	.tabs-list__text
		line-height tabLinkLineHeight

.tabs-list__item
	position relative
	margin 0 10px
	height tabLinkHeight
	transition all linear .2s
	cursor pointer

	&.tabs-list__item--border,
	&.tabs-list__item--border-transparent
		padding 0 25px
		margin 0 25px
		border-top 2px solid transparent
		border-bottom 2px solid transparent

		@media screen and (max-width smallDesktop)
			padding 0 5px
			margin 0 15px

	&.tabs-list__item--active,
	&:hover
		color tabActiveLinkColor
		border-color tabActiveLinkColor

		.tab-border--right,
		.tab-border--left
			opacity 1

		.tab-border-transparent--left,
		.tab-border-transparent--right

			&:after,
			&:before
				opacity 1

.tab-border--right,
.tab-border--left
	opacity 0
	transition all linear .2s

.tab
	display none

	&.tab--active
		display block

.tab-border--left
	absolute($top:-2px,$left:-12px)
	size(12px,40px)
	overflow hidden

	&:after
		absolute($top:-1px,$left:0)
		arrow-left(tabActiveLinkColor,21px,12px)

	&:before
		absolute($top:2px,$left:2px)
		z-index 1
		arrow-left(#fff,18px,10px)


.tab-border--right
	absolute($top:-2px,$right:-12px)
	size(12px,40px)
	overflow hidden

	&:after
		absolute($top:-1px,$right:0)
		arrow-right(tabActiveLinkColor,21px,12px)

	&:before
		absolute($top:2px,$right:2px)
		z-index 1
		arrow-right(#fff,18px,10px)


.tab-border-transparent--left
	absolute($top:0,$bottom:0,$left:0)

	&:after,
	&:before
		content ''
		display block
		opacity 0
		size(23px,2px)
		background-color tabActiveLinkColor

	&:after
		absolute($top:7px,$left:-16px)
		transform rotate(-60deg)

	&:before
		absolute($bottom:7px,$left:-16px)
		transform rotate(60deg)


.tab-border-transparent--right
	absolute($top:0,$bottom:0,$right:0)

	&:after,
	&:before
		content ''
		display block
		opacity 0
		size(23px,2px)
		background-color tabActiveLinkColor

	&:after
		absolute($top:7px,$right:-16px)
		transform rotate(60deg)

	&:before
		absolute($bottom:7px,$right:-16px)
		transform rotate(-60deg)