chooseTabsColor     = #fff
chooseTabsListColor = #3f2b0d
chooseCompassColor  = #fff
chooseInfoTextColor = #fff

.s-choose
	position relative

.choose-actions
	absolute($top:105px,$left:60px)
	z-index 1

.choose-actions--black

	@media screen and (max-width 1500px)
		position static
		padding-top 50px

	.g-section-title
		color chooseTabsListColor

	.choose__tabs-list
		color chooseTabsListColor

.choose__tabs-list
	color chooseTabsColor

@import "choose/map.styl"
@import "choose/map-popup.styl"
@import "choose/list.styl"

