locationBorderColor     = #fff
locationContentWidth    = 500px
locationContentHeight   = 150px

locationTitleFontSize   = rem(26)
locationTitleFontWeight = 600

locationTextFontSize    = rem(20)

#location-map,
#location-near,
#location-road
	height 600px

	@media screen and (max-width phabletWidth)
		height 300px

.s-location
	position relative
	border-bottom 4px solid locationBorderColor
	background-color #fff

	.g-section-title
		absolute($top:60px,$left:50%)
		z-index 1
		transform translateX(-50%)


.location__border-line
	absolute($top:160px,$left:50%)
	z-index 1
	transform translateX(-50%)
	max-width 450px
	margin 0
	padding 0
	overflow visible

	@media screen and (max-width smallMobileWidth)
		max-width 300px
		overflow auto
		margin-right 20px
		padding 0 30px

	.border-line
		width 100%
		max-width 450px
		min-width 400px

.location__tabs-list
	flex 1 0 auto
	justify-content space-between

.location-content
	absolute($bottom:-75px,$left:50%)
	transform translateX(-50%)
	size(100%,locationContentHeight)
	max-width locationContentWidth
	padding-top 30px
	background-color locationBorderColor
	text-align center

	@media screen and (max-width bigPhoneWidth)
		display none

	&:before
		absolute($top:0,$left:-50px,$bottom:0)
		arrow-left(locationBorderColor,75px,50px)

	&:after
		absolute($top:0,$right:-50px,$bottom:0)
		arrow-right(locationBorderColor,75px,50px)

.location__title
	display block
	margin-bottom 10px
	font-size locationTitleFontSize
	font-weight locationTitleFontWeight

.location__text
	display block
	margin-bottom 5px
	font-size locationTextFontSize

	&:last-child
		margin-bottom 0