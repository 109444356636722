contentPageBgColor   = #f1f1f1
contentTextColor     = #000
contentDateTextColor = #3f2b0d
contentBlockBgColor  = #fff
contentBlockShadow   = 0px 4px 50px rgba(0, 0, 0, 0.05)

.s-content-page
	padding 80px 0 130px
	background-color contentPageBgColor

	@media screen and (max-width bigPhoneWidth)
		padding 50px 0

.page__date
	display block
	margin-bottom 35px
	text-align center
	color contentDateTextColor

.content-block
	padding 60px 112px
	background-color contentBlockBgColor
	box-shadow contentBlockShadow

	@media screen and (max-width phabletWidth)
		padding 50px

	@media screen and (max-width smallMobileWidth)
		padding 20px

.content__text
	margin-bottom 35px
	line-height 1.5
	color contentTextColor

	&:last-child
		margin-bottom 0


.content__img
	margin-bottom 35px
	max-width 100%