housingTopBorderColor     = #ffc156

housingTitleFontSize      = rem(40)
housingInfoValFontSize    = rem(36)
housingInfoValSupFontSize = rem(14)
chooseSectionFontSize     = rem(18)

.housing-screen__top
	padding 20px 50px
	margin-bottom 50px
	border 2px solid housingTopBorderColor
	border-radius 5px

.housing__title
	display block
	margin-bottom 25px
	font-size housingTitleFontSize
	text-align center

.housing-short-info
	display flex
	justify-content space-between

.housing-short-info__col
	flex 0 0 45%
	width 45%
	text-align center

.housing-short-info__val
	position relative
	display block
	margin-bottom 5px
	font-size housingInfoValFontSize

	sup
		absolute($top:2px)
		font-size housingInfoValSupFontSize

.housing-screen-choose
	cursor pointer

	img
		display block
		margin 0 auto
		max-width 100%

.choose-section__title
	display block
	margin-bottom 10px
	text-align center
	font-size chooseSectionFontSize


