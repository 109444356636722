footerBgColor        = #452300
footerCopyrightColor = #fff
footerCopyrightSize  = rem(10)

footerProjectColor   = #fbca0d
footerProjectSize    = rem(12)
footerProjectWeight  = 600

footer
	flex 0 0 auto
	background-color footerBgColor

.footer
	position relative
	display flex
	align-items flex-end
	justify-content center
	padding 30px 24px 24px

	@media screen and (max-width bigPhoneWidth)
		flex-direction column
		align-items center
		text-align center

.footer-copyright
	absolute($bottom:24px,$left:0)
	color footerCopyrightColor
	font-size footerCopyrightSize

	@media screen and (max-width bigPhoneWidth)
		position static
		order 2

.footer-center
	text-align center

	@media screen and (max-width bigPhoneWidth)
		order 1
		margin-bottom 10px

.footer-social-list
	margin-bottom 30px

.footer__project-link
	color footerProjectColor
	font-size footerProjectSize
	font-weight footerProjectWeight

