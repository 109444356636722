chooseListBorderColor         = #ccc
chooseListTitleFontSize       = rem(22)
houseFreeTextColor            = #56b64b
houseBusyTextColor            = #ccc
houseListKeyFontSeize         = rem (13)
houseChooseAttentionColor     = #ffae23
houseChooseAttentionIconColor = #fff

.choose-list
	padding 120px 0 150px

	@media screen and (max-width 1500px)
		padding 50px 0 50px

.house-list-wrap
	display flex
	justify-content space-around
	padding-top 30px
	border-top 1px dashed chooseListBorderColor

	@media screen and (max-width bigPhoneWidth)
		flex-direction column

.house-list
	flex 0 0 45%
	width 45%

	@media screen and (max-width bigPhoneWidth)
		flex 1
		width 100%
		padding  0 15px

.house-list__item
	padding 15px 10px 15px 20px
	border 1px solid transparent
	border-radius 4px
	transition all linear .5s

	&.house-list__item--active
		border-color chooseListBorderColor


.house-list__item--active

	.house-list__choose
		opacity 1

	.house-list__title
		color houseChooseAttentionColor

	.house-list__icon
		border 1px solid houseChooseAttentionColor

		.icon
			fill houseChooseAttentionColor

		.icon-arrow-up-thin
			display block

		.icon-arrow-down-thin
			display none


.house-list__title
	display flex
	align-items center
	font-size chooseListTitleFontSize
	cursor pointer
	transition all linear .5s

.house-list__choose
	opacity 0
	position relative
	size(24px)
	margin-left 30px
	background-color houseChooseAttentionColor
	transition all linear .5s

	&:before
		content ''
		absolute($top:0,$left:-7px)
		arrow-left(houseChooseAttentionColor,12px,7px)

	.icon
		size(3px,17px)
		absolute-center()
		fill houseChooseAttentionIconColor


.house-list__icon
	position relative
	size(26px)
	margin-left auto
	border 1px solid chooseListBorderColor
	border-radius 50%

	.icon
		absolute-center()
		size(10px)
		fill chooseListBorderColor

	.icon-arrow-up-thin
		display none

	.icon-arrow-down-thin
		display block

.house-list__line
	display flex
	padding 8px 0

.house-list__val
	width 70%
	flex-shrink 0

.house-list__key
	font-size houseListKeyFontSeize
	color houseFreeTextColor

.house-list--busy
	color houseBusyTextColor

	.house-list__key
		color houseBusyTextColor

.house-list__content
	padding-top 15px
	display none