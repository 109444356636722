mapPopupBackIconColor            = #707070
mapPopupExitIconColor            = #707070
mapPopupBackFontSize             = rem(14)

mapPopupHouseToBorderColor       = #e3e3e3

mapPopupHouseTitleFontSize       = rem(30)
mapPopupHouseTitleFontWight      = 600

housePrevContentBorderColor      = #ccc
housePrevContentBgColor          = #f8f8f8

housePrevInfoTitleFontSize       = rem(14)
housePrevInfoTitleFontWeight     = 500

housePrevInfoTitleNumberFontSize = rem(25)

housePrevInfoListBgColor         = #fff
housePrevInfoListShadow          = 0px 2px 4px rgba(0, 0, 0, 0.15)

houseTabActiveBgColor            = #ffd276

housePrevInfoListFontSize        = rem(13)

.map-popup-top

	&.map-popup-top--house
		padding-bottom 15px
		align-items center
		border-bottom 1px solid mapPopupHouseToBorderColor

		@media screen and (max-width smallDesktop)
			justify-content space-between

.map-popup-exit
	margin 0 10px
	cursor pointer

	.icon
		size(18px,25px)
		fill mapPopupExitIconColor

.house-screen__top
	display flex
	justify-content space-between
	align-items center
	margin-bottom 20px

	@media screen and (max-width smallDesktop)
		display none

.form-success__text
	font-size rem(14)
	color #56b64b


.house__title
	font-size mapPopupHouseTitleFontSize
	font-weight mapPopupHouseTitleFontWight

.house-title-mobile
	display none
	font-size rem(22)
	font-weight 600
	margin 0 10px

	@media screen and (max-width smallMobileWidth)
		display block

.house-prev-content
	display flex
	justify-content space-between
	align-items center
	padding 5px
	margin-bottom 20px
	max-height 280px
	overflow auto
	background-color housePrevContentBgColor
	border 1px solid housePrevContentBorderColor

.house-prev-img
	position relative
	width 150px
	flex-shrink 0
	margin-right 10px
	cursor pointer

	@media screen and (max-width smallDesktop)
		width 130px

	&:hover

		.house-prev-img__zoom
			opacity 1

	img
		width 100%

	.house-prev-img__zoom
		absolute($top:15px,$left:15px)
		z-index 1
		size(30px)
		opacity 0
		transition all linear .3s

.house-prev-info
	flex 1

.house-prev-info__title
	margin-bottom 20px
	font-size housePrevInfoTitleFontSize
	font-weight housePrevInfoTitleFontWeight
	text-align center

.house-prev-info__title-number
	font-size housePrevInfoTitleNumberFontSize

.js-house-tab
	display none

	&.js-house-tab--active
		display block

.house-prev-info-list
	margin-bottom 30px
	padding 3px
	background-color housePrevInfoListBgColor
	box-shadow housePrevInfoListShadow

	@media screen and (max-width smallDesktop)
		margin-bottom 15px

.house-prev-info-list__item
	display flex
	padding 8px 5px
	cursor pointer
	font-size housePrevInfoListFontSize
	transition all linear .4s

	&.js-house-tab__link--active,
	&:hover
		background-color houseTabActiveBgColor

.house-square
	margin-left auto
	font-size rem(13)
	font-weight 500

.house-order--mobile-btn
	display none

	@media screen and (max-width smallDesktop)
		display block

.house-gallery
	display flex
	align-items center
	justify-content center
	cursor pointer

	@media screen and (max-width smallDesktop)
		margin-bottom 15px

	span
		margin-left 10px

.house-screen__choose

	img
		display block
		margin 0 auto
		max-width 100%