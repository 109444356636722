houseTabActiveBgColor            = #ffd276

.house-info
	display flex
	justify-content space-between

.house-img
	flex 1 0 35%
	width 35%

	img
		max-width 100%

.house-info-close
	absolute($top:20px,$right:15px)
	z-index 1
	size(32px)
	border 1px solid mapPopupBorderColor
	border-radius 50%
	cursor pointer

	.icon
		absolute-center()
		size(10px)
		fill mapPopupCloseIconColor

.house-content
	position relative
	display flex
	align-items center

	@media screen and (max-width bigPhoneWidth)
		align-items flex-start

	.js-house-tabs-content

		@media screen and (max-width bigPhoneWidth)
			display none

.house-info-nav
	width 37px
	flex-shrink 0
	margin-right 35px

	@media screen and (max-width bigPhoneWidth)
		margin-right 0
		margin-left  35px

	&>span
		display block
		text-align center
		margin-bottom 10px
		font-size rem(14px)
		font-weight 500

.house-info-list__item
	position relative
	size(37px)
	margin-top -1px
	border 1px solid houseTabActiveBgColor
	cursor pointer
	transition all linear .4s

	&.js-house-tab__link--active,
	&:hover
		background-color houseTabActiveBgColor

	span
		absolute-center()

.house-content-top
	margin-bottom 40px

.house__housing-number
	display block
	margin-bottom 5px
	font-size rem(14)

.house__title
	font-size rem(24)
	font-weight 600

.house-content__title
	display block
	margin-bottom 15px
	font-size rem(34)
	font-weight 600

.house-content__text
	line-height 1.7