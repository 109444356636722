mainNavLinkColor         = #fff
mainNavLinkHoverColor    = #ffae23
mainNavLinkActiveBgColor = #88480e
mainNavLinkFontWeight    = 500
mainNavLinkHeight        = 44px


.main-nav

	@media screen and (max-width smallDesktop)
		display none

.main-nav-list
	display flex
	padding 3px 0

.main-nav-list__link
	position relative
	display block
	height mainNavLinkHeight
	line-height mainNavLinkHeight
	padding 0 23px
	font-weight mainNavLinkFontWeight
	color mainNavLinkColor
	text-transform uppercase

	&:hover
		color mainNavLinkHoverColor

	&.main-nav-list__link--active
		padding 0 11px
		margin 0 12px
		background-color mainNavLinkActiveBgColor

		&:after
			absolute($top:0,$bottom:0,$right:-12px)
			arrow-right(mainNavLinkActiveBgColor,23px,12px)

		&:before
			absolute($top:0,$bottom:0,$left:-12px)
			arrow-left(mainNavLinkActiveBgColor,23px,12px)