headerBgColor         = #663300
headerHeight          = 50px
headerPhoneFontWeight = 600
headerPhoneFontSize  = rem(26)
headerPhoneFontSizeMobile  = rem(14)
headerPhoneColor      = #fff

header
	fixed($top:0,$right:0,$left:0)
	z-index 10
	display flex
	align-items center
	justify-content flex-start
	flex 0 0 auto
	padding-left 15px
	background-color headerBgColor
	height  headerHeight
	transition all linear .5s

	&.header-mobile--show
		left -170px
		right 170px

.header__logo
	margin-right auto
	max-width 117px

.header__phone
	margin-right 15px
	font-weight headerPhoneFontWeight
	font-size headerPhoneFontSize
	color headerPhoneColor
	line-height 1

	@media screen and (max-width smallMobileWidth)
		font-size headerPhoneFontSizeMobile
		margin-right 10px

.header__sandwich
	display none

	@media screen and (max-width smallDesktop)
		display block