.g-input-block
	margin-bottom 10px

	&:last-child
		margin-bottom 20px

.g-input
	size(100%,50px)
	line-height 48px
	padding 0 10px
	border 1px solid #ccc
	background-color #f8f8f8
	font-size rem(14)
	transition all linear .3s

	&:focus
		border 2px solid #fbca0d
		line-height 46px
		font-size rem(16)

	&.error
		border-color #ef5757

label

	&.error
		font-size rem(12)
		color #ef5757




.form-agree__text
	margin-bottom 30px
	font-size rem(14)
