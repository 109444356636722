bannerTextColor       = #fff
bannerLinkFontStyle   = italic
bannerLinkFontWeight  = 500
bannerLinkFontSize    = rem(18)
bannerLinkBorderColor = #57bcab

.s-banner
	position relative
	padding 50px 0 100px
	text-align center
	color bannerTextColor

	@media screen and (max-width phabletWidth)
		padding-bottom 30px
		background url('../img/general/banner-bg.jpg') center no-repeat
		background-size cover

	.container
		position relative
		z-index 2

.banner-video__placeholder
	absolute-all(0)
	z-index 1


.banner-video
	fixed($top:0,$right:0,$left:0)
	z-index -1

	@media screen and (max-width phabletWidth)
		display none

	video
		absolute($top:0,$left:0)
		size(auto)
		min-width 100%
		min-height 100%

.banner
	padding-top 80px
	opacity 1
	transition all linear .7s

	@media screen and (max-width phabletWidth)
		padding-top 30px

	&.banner-half-opacity
		opacity .3


.banner__logo
	margin-bottom 70px

	@media screen and (max-width smallMobileWidth)
		max-width 200px

.banner-link
	position relative
	width 100%
	max-width 170px
	margin 0 auto
	padding 0 10px 10px

	&:before,
	&:after
		content ''
		absolute($bottom:0)
		size(70px,2px)
		background-color bannerLinkBorderColor

	&:before
		left 0

	&:after
		right 0

	.icon
		absolute($bottom:-8px,$left:50%)
		size(16px)
		margin-left -8px
		fill bannerLinkBorderColor

.banner-link__text
	color bannerTextColor
	font-weight bannerLinkFontWeight
	font-style bannerLinkFontStyle
	font-size bannerLinkFontSize


.banner-play
	display none
	position relative
	size(70px)
	margin 20px auto
	border-radius 50%
	border 1px solid #fff

	@media screen and (max-width phabletWidth)
		display block

	&:after
		content ''
		absolute-center()
		arrow-right(#fff,14px,24px)

#banner-video-mobile
	position relative
	width 100%
	background-color #fff
	padding-top 50px