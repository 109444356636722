galleryBgColor              = #663300


.s-gallery
	padding 150px 0 100px
	background-color galleryBgColor

	@media screen and (max-width bigPhoneWidth)
		padding 50px 0

.gallery__tabs-content
	margin-top -25px

	@media screen and (max-width bigPhoneWidth)
		margin-top 0

.gallery__border-line

	@media screen and (max-width bigPhoneWidth)
		margin-bottom 40px

	.border-line-wrap-inner
		width 400px
		margin 0 auto

.gallery__tabs-list
	flex 1 0 auto
	justify-content space-between

.gallery-slider-item
	max-width 620px
	margin 0 20px
	height auto

	img
		width 100%