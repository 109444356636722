@import 'fonts.styl'
@import 'vars.styl'
@import 'libs.styl'
@import 'utils/mixins.styl'

html
	height 100%
	line-height 1.3
	font-size 16px


body
	height 100%
	font-family mainFont
	color baseTextColor


h1,h2,h3,h4,h5,h6
	margin 0


ul,li
	margin 0
	padding 0
	list-style-type none

p
	margin 0

a
	text-decoration none
	color baseTextColor

audio,
canvas,
iframe,
img,
svg,
video
	vertical-align middle


textarea
	resize none

input,
select,
button
	outline none


*, *:before, *:after
	box-sizing border-box
	outline none

.container
	width 100%
	max-width containerSmallWidth
	margin 0 auto

	&.container--big
		max-width containerBigWidth

	&.container--middle
		max-width containerMiddleWidth

	&.container--content
		max-width containerContentWidth

.wrapper
	overflow hidden
	min-height 100%

.wrapper-inn
	position relative
	left 0
	display flex
	flex-direction column
	min-height 100vh
	transition all linear .5s

	&.wrapper-inn--menu-show
		left -170px

.main-content
	flex 1 0 auto

// МОДУЛИ
// Стили шапки
@import 'modules/header/header.styl'

// Стили футера
@import 'modules/footer/footer.styl'

// Стили меню
@import 'modules/navigation/main-nav.styl'

// Стили меню мобильного
@import 'modules/navigation/main-nav-mobile.styl'

//Компоненты
@import "components/components.styl"


// СТРАНИЦЫ

// Стили главной страницы
@import 'pages/index.styl'

// Стили внутренней страницы
@import 'pages/inside.styl'

// Стили страницы выбора
@import 'pages/choose.styl'
