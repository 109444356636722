sandwichLineColor = #fff
headerBgColor     = #663300

.sandwich
	position relative
	width 60px
	height 50px
	cursor pointer

	&:before
		content ''
		absolute($top:0,$left:-10px)
		arrow-left(headerBgColor,25px,10px)

	&.sandwich--active

		.sandwich-line
			background-color sandwichLineColor

		.sandwich-line--top
			top 24px
			transform rotate(-45deg)
			transition all linear .4s

		.sandwich-line--middle
			opacity 0
			transition all linear .4s

		.sandwich-line--bottom
			top 24px
			transform rotate(45deg)
			transition all linear .4s

.sandwich-line
	position absolute
	left 18px
	display block
	width 24px
	height 2px
	background-color sandwichLineColor

.sandwich-line--top
	top 18px
	transition all linear .4s

.sandwich-line--middle
	top 24px
	opacity 1
	transition all linear .4s

.sandwich-line--bottom
	top 30px
	transition all linear .4s