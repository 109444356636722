socialListColor = #fbca0d

.social-list
	display flex
	align-items center
	justify-content center

.social-list__item
	position relative
	size(36px)
	margin 0 11px
	border-radius 50%
	border 2px solid socialListColor

	.icon
		absolute-center()
		fill socialListColor
		size(16px)
