contactsBgColor         = #f1f1f1

contactsPhoneSize       = rem(24)
contactsPhoneWeight     = 500

contactsTimeSize        = rem(14)

contactsSchemeIconColor = #fbca0d

personWorkFontSize      = rem(13)

.s-contacts
	padding-top 60px
	background-color contactsBgColor

	.container--middle

		@media screen and (max-width smallDesktop)
			padding 0 20px

.contacts__tabs-list
	margin-bottom 45px

	.tab-border--left

		&:after
			arrow-left(tabActiveLinkColor,21px,12px)

		&:before
			arrow-left(contactsBgColor,18px,10px)


	.tab-border--right

		&:after
			arrow-right(tabActiveLinkColor,21px,12px)

		&:before
			arrow-right(contactsBgColor,18px,10px)

.contacts-row
	display flex
	margin-bottom 45px

	@media screen and (max-width bigPhablet)
		justify-content center

	@media screen and (max-width smallMobileWidth)
		flex-direction column
		align-items center

#office-map,
#sell-map
	height 320px
	width 100%

.contacts-phone
	margin-right 55px

	@media screen and (max-width smallMobileWidth)
		margin-right 0
		margin-bottom 10px

.contacts-phone__link
	display block
	margin-bottom 15px
	font-size contactsPhoneSize
	flex-wrap contactsPhoneWeight
	white-space nowrap

	&:last-child
		margin-bottom 0

.contacts-address
	width 100%
	max-width 180px
	margin-right 10px

	@media screen and (max-width smallMobileWidth)
		display flex
		flex-direction column
		align-items center
		text-align center

.address
	display block
	margin-bottom 15px

	@media screen and (max-width smallMobileWidth)
		order 3

.contacts-mail
	display block

	@media screen and (max-width smallMobileWidth)
		order 1

.contacts-time
	display block
	margin-bottom 30px
	font-size contactsTimeSize


	@media screen and (max-width smallMobileWidth)
		order 2

.contacts-scheme
	display flex
	align-items center

	@media screen and (max-width smallMobileWidth)
		order 4

.contacts-scheme-icon
	position relative
	size(40px)
	margin-right 30px
	flex-shrink 0
	background-color contactsSchemeIconColor
	border-radius 50%

	.icon
		absolute($bottom:5px,$left:5px)
		size(44px)

.contacts-persons
	display flex
	margin-left auto

	@media screen and (max-width bigPhablet)
		display none

.person
	flex 0 0 110px
	margin-right 56px
	display flex
	flex-direction column
	align-items center
	text-align center

	&:last-child
		margin-right 0

.person-photo
	size(108px)
	margin-bottom 15px
	border-radius 50%
	background-size cover

.person__name
	margin-bottom 10px

.person__work
	font-size personWorkFontSize


