@font-face {
	font-family: 'Fira Sans Condensed';
	src: local('Fira Sans Condensed Regular'), local('FiraSansCondensed-Regular'),
			url('../fonts/firasanscondensed-regular/Firasanscondensedregular.woff2') format('woff2'),
			url('../fonts/firasanscondensed-regular/Firasanscondensedregular.woff') format('woff'),
			url('../fonts/firasanscondensed-regular/Firasanscondensedregular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Fira Sans Condensed';
	src: local('Fira Sans Condensed Medium'), local('FiraSansCondensed-Medium'),
			url('../fonts/firasanscondensed-medium/Firasanscondensedmedium.woff2') format('woff2'),
			url('../fonts/firasanscondensed-medium/Firasanscondensedmedium.woff') format('woff'),
			url('../fonts/firasanscondensed-medium/Firasanscondensedmedium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Fira Sans Condensed';
	src: local('Fira Sans Condensed Medium Italic'), local('FiraSansCondensed-MediumItalic'),
			url('../fonts/firasanscondensed-medium-italic/Firasanscondensedmediumitalic.woff2') format('woff2'),
			url('../fonts/firasanscondensed-medium-italic/Firasanscondensedmediumitalic.woff') format('woff'),
			url('../fonts/firasanscondensed-medium-italic/Firasanscondensedmediumitalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Fira Sans Condensed';
	src: local('Fira Sans Condensed SemiBold'), local('FiraSansCondensed-SemiBold'),
			url('../fonts/firasanscondensed-semibold/Firasanscondensedsemibold.woff2') format('woff2'),
			url('../fonts/firasanscondensed-semibold/Firasanscondensedsemibold.woff') format('woff'),
			url('../fonts/firasanscondensed-semibold/Firasanscondensedsemibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}