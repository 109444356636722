mobileMenuBgColor = #663300
mainNavMobileLinkColor         = #fff
mainNavMobileLinkFontWeight = 500
mainNavMobileLinkHeight     = 44px

.main-nav-mobile
	fixed($top:0,$bottom:0,$right:-170px)
	z-index 100
	width 170px
	padding-top 50px
	background-color mobileMenuBgColor
	border-left 1px solid #fff
	transition all linear .5s

	&.main-nav-mobile--show
		right 0

.main-nav-mobile-list__link
	position relative
	display block
	height mainNavMobileLinkHeight
	line-height mainNavMobileLinkHeight
	padding 0 23px
	font-weight mainNavMobileLinkFontWeight
	color mainNavMobileLinkColor
	text-transform uppercase
	transition all linear .3s