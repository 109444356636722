.map-p-screen--house-book
	width 100%
	max-width 370px

.map-popup-top--house-book
	padding-bottom 15px
	margin-bottom 15px
	border-bottom 1px solid #e3e3e3

.house-book__title
	display block
	margin-bottom 10px
	font-weight 600
	font-size rem(30)


.house-book__descr
	display block
	margin-bottom 25px
	font-weight 600